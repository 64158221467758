import React from "react";
import "./MainPage.scss";
import Header from "../../Components/Header/Header";
import SectionOne from "../SectionOne/SectionOne";
import SectionTwo from "../SectionTwo/SectionTwo";
import Section4 from "../Section4/Section4";
// import Section5 from "../Section5/Section5";
// import Section6 from "../Section6/Section6";
import Section7 from "../Section7/Section7";
import Footer from "../../Components/Footer/Footer";

function MainPage() {
  return (
    <div className="main_page_cont">
      <Header />

      <SectionOne />
      <div
        style={{
          backgroundColor: "var(--ofWhite)",
          borderTop: "5px solid #E0E0E0",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <SectionTwo />

        <Section4 />
        {/* <Section5 /> */}
      </div>
      <div
        style={{
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {/* <Section6 /> */}
      </div>
      <div
        style={{
          backgroundColor: "var(--ofWhite)",
          display: "flex",
          flexDirection: "column",

          alignItems: "center",
        }}
      >
        <Section7 />
      </div>
      <div
        style={{
          backgroundColor: "#1E1E1E",
          display: "flex",
          justifyContent: "center",
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
        }}
      >
        <Footer />
      </div>
    </div>
  );
}

export default MainPage;
