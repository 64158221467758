import React, { useEffect, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import "./Header.scss";
import logo from "../../Assets/Images/logo.svg";
import Button from "../Button/Button";
import menu from "../../Assets/Images/menu.svg";
import close from "../../Assets/Images/close.svg";

import gsap from "gsap";
function Header() {

  // Popup payments
  const [showPopup3, setShowPopup3] = useState(false);

  const handleOpenPopup3 = () => {
    setShowPopup3(true); 
  };

  const handleClosePopup3 = () => {
    setShowPopup3(false); 
  };

  // smooth scroll to section
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      const targetElement = document.getElementById(location.hash.substring(1));

      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  // close menu on link click
  const [open, setOpen] = useState(false);
  const closeMenu = () => {
    setOpen(false);
  };

  // bg change on fixed nav scroll
  const [isSmall, setIsSmall] = useState(false);
  const changeNavBg = () => {
    if (window.scrollY >= 100) {
      gsap.to(".header_main_cont", {
        background: "rgba(255, 255, 255, 0.2)",
        boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
        backdropFilter: "blur(5px)",
        borderRadius: "10px",

        ease: "ease-in",
        duration: "0.5",
      });
    } else {
      gsap.to(".header_main_cont", {
        background: "none",
        boxShadow: "none",
        backdropFilter: "blur(0px)",
        ease: "ease-in",
        duration: "0.5",
      });
    }
  };
  window.addEventListener("scroll", changeNavBg);

  useEffect(() => {
    if (window.innerWidth < 600) {
      setIsSmall(true);
    } else {
      setIsSmall(false);
    }
  }, [window.innerWidth]);

  // when done fixing, on line 74, re-add className 'menu_cont'
  return (
    <>
    <div className="header_main_cont">
      {!isSmall ? (
        <>
          <div className="p-rel"> 
            <a href="/"><img src={logo} className="header_logo" alt="logo" /></a>
            <p>
              Managed Data, Observability, Insight & Data Security Solutions
            </p>
          </div>
          <div className="display-none">
            <div className="menu_item">
              <a href="/">Home</a>
            </div>
            <div className="menu_item">
              <Link to="/#solutions">Solutions</Link>
            </div>
            <div className="menu_item">
              <a href="/#about">About</a>
            </div>
          </div>
          <span className="header_btn">
            {/* <a href="https://meetings.hubspot.com/nomnom/30-min">
              <Button text="Contact Us" />
            </a> */}
            <a style={{ marginLeft: '.5rem' }}>
              <Button text="Flexible Payment Options" onClick={handleOpenPopup3}/>
            </a>
          </span>
        </>
      ) : (
        <div className="menu_cont2">
          <a style={{ marginLeft: '.5rem' }} className="modal-payments-link">
            <Button text="Flexible Payment Options" onClick={handleOpenPopup3}/>
          </a>
          <a href="/">
            <img src={logo} className="header_logo2" alt="logo" />
          </a>
          <p className="m-logo-text">
            Managed Data, Observability, Insight & Data Security Solutions
          </p>
          <img
            src={menu}
            className="display-none header_menu"
            alt="logo"
            onClick={() => setOpen(!open)}
          />
          {open && (
            <div className="menu_cont_mobile">
              <div className="menu_cont3">
                <img src={logo} className="header_logo2" alt="logo" />
                <p>
                  Managed Data, Observability, Insight & Data Security Solutions
                </p>
                <img
                  src={close}
                  className="header_menu"
                  alt="logo"
                  onClick={closeMenu}
                />
              </div>
              <div
                className="menu_item_mob"
              >
                <a href="/" onClick={closeMenu}>Home</a>
              </div>
              <div
                className="menu_item_mob"
              >
                <a href="/#solutions" onClick={closeMenu}>Solutions</a>
              </div>
              <div
                className="menu_item_mob"
              >
                <a href="/#about" onClick={closeMenu}>About</a>
              </div>
              <div
                className="menu_item"
              >
               <a href="https://meetings.hubspot.com/nomnom/30-min" onClick={closeMenu}>Contact Us</a>
              </div>
            </div>
          )}
        </div>
      )}
    </div>

    <div className={showPopup3 ? 'modal display-block' : 'modal display-none'}>
      <section className="modal-main modal-payments">
        <button onClick={handleClosePopup3}>×</button>
        <h2>
          Flexible Payment Options
        </h2>
        <p>
          <strong>Enhance your business with the power of Data and AI today!</strong>
          <br/><br/>
          No upfront costs, flexible payment options.
          <br/><br/>
          Eliminate the traditional barriers to AI evolution for organizations of all sizes. 
          <br/><br/>
          Nom Nom is your trusted data & Ai partner, we offer all our clients the flexibility they need to advance their data & AI initiatives, without the burden of upfront costs!
          <br/><br/>
          Schedule a meeting or contact us today hello@nomnom.ai
        </p>
      </section>
    </div>
    </>
  );
}

export default Header;
