import React from "react";
import "./Accredations.scss";
import acc1 from "../../Assets/Images/acc1.png";
import acc2 from "../../Assets/Images/acc2.png";
import acc3 from "../../Assets/Images/acc3.png";
import acc4 from "../../Assets/Images/acc4.png";

import acc5 from "../../Assets/Images/acc5.svg";
import acc6 from "../../Assets/Images/acc6.png";

function Accredations() {
  return (
    <div className="accredation_main_cont">
       <div className="accredtion_div">
        {/* <div className="our_ach">Our Achievements</div> */}
        <div className="accredation_text">
          <h2>
            Accreditations
          </h2>
        </div>
      </div>
      <div className="flex-it">
        <div className="accredtion1">
          <img src={acc1} className="accredation_img" alt="accredation" />
          <p>
            HIPAA <br/>Compliant
          </p>
        </div>
        <div className="accredtion1">
          <img src={acc2} className="accredation_img" alt="accredation" />
          <p>
            SOC2 TYPE2 <br/>Compliant
          </p>
        </div>
        <div className="accredtion1">
          <img src={acc3} className="accredation_img" alt="accredation" />
          <p>
            GDPR <br/>Compliant
          </p>
        </div>
        <div className="accredtion1">
          <img src={acc4} className="accredation_img" alt="accredation" />
          <p>
            ISO <br/>Certified
          </p>
        </div>
        <div className="accredtion1">
          <img src={acc5} className="accredation_img" alt="accredation" />
          <p>
            PCI-DSS <br/>Compliant
          </p>
        </div>
        <div className="accredtion1">
          <img src={acc6} className="accredation_img" alt="accredation" />
          <p>
            ADA <br/>Compliant
          </p>
        </div>
      </div>
    </div>
  );
}

export default Accredations;
