import React, { useRef, useEffect, useState } from "react";
import "./Card.scss";
import arrow from "../../Assets/Images/arrowLong.svg";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
function Card({ image, video, title, text }) {
  const videoRef = useRef(null);
  const container = useRef();
  const { contextSafe } = useGSAP({ scope: container });

  const handleMouseEnter = () => {
    handleResize();

    if (videoRef.current && videoRef.current.paused) {
      videoRef.current.play().catch((error) => {
        // Handle play error, if any

        console.error("Error playing video:", error);
      });
    }
  };

  const handleMouseLeave = () => {
    handleResizeBack();
  };
  const handleResize = contextSafe(() => {
    gsap.to(container.current, {
      duration: 0.5,
      display: "flex",
      bottom: "-35px",
    });
  });

  const handleResizeBack = contextSafe(() => {
    gsap.to(container.current, {
      duration: 0.5,
      bottom: "35px",
      display: "none",
    });
  });

  /*
    When you want to put the hover effect back in, revert to
    the hovers were removed because they look ass and don't work very well
    <div
      className="card_main_div"
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
    >
  */
    const [isSmall, setIsSmall] = useState(false);
  
    useEffect(() => {
      if (window.innerWidth < 600) {
        setIsSmall(true);
      } else {
        setIsSmall(false);
      }
    }, [window.innerWidth]);
  
  return (
    <div
      className="card_main_div"
      
      onMouseEnter={() => !isSmall ? handleMouseEnter() : console.log('')}
      onMouseLeave={() => !isSmall ? handleMouseLeave() : console.log('')}
    >
      <div className="card_box">
        <video
          className="card_video"
          ref={videoRef}
          width="100%"
          height="auto"
          muted
          playsInline
          preload="auto"
          poster={image}
        >
          <source src={video} type="video/mp4" />
        </video>
        <div className="card_title">{title}</div>
        <div className="card_text">{text}</div>
      </div>

    </div>
  );
}

export default Card;
