import React from "react";
import "./SectionTwo.scss";
import arrow from "../../Assets/Images/arrow.svg";
import Button from "../../Components/Button/Button";
import image1 from "../../Assets/Images/img1.png";
import image2 from "../../Assets/Images/img2.png";
import image3 from "../../Assets/Images/img3.png";
import image1mobile from "../../Assets/Images/box-1.jpg";
import image2mobile from "../../Assets/Images/box-2.jpg";
import image3mobile from "../../Assets/Images/box-3.jpg";
import Card from "../../Components/Card/Card";
import video1 from "../../Assets/Videos/card1.webm";
import video2 from "../../Assets/Videos/card2.webm";
import video3 from "../../Assets/Videos/card3.webm";
import Trusted from "../Trusted/Trusted";
import Accredations from "../Accredations/Accredations";
import Section3 from "../Section3/Section3";

function SectionTwo() {

  const handleScroll = (className) => {
    const element = document.querySelector(`.${className}`);
  
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  };
  
  const handleScrollToContact = () => {
    handleScroll("section7_main");
  };
  
  return (
    <div className="section2_main" id="solutions">
      <div className="section2_header">
        <h2 className="section2_title">
        A Pioneer in Data Engineering and Automation <br/>as a Service
        </h2>
        <div className="section2_sub">
          We specialize in transforming your data management experience. Our suite of services empowers your business with streamlined, cost-effective solutions for complex data challenges.
        </div>
        <Button
          onClick={() => window.location = 'https://meetings.hubspot.com/nomnom/30-min'}
          text={
            <div className="contact_sec1">
              Schedule a Call
              <img src={arrow} alt="Arrow icon" />
            </div>
          }
        />
      </div>
      <div className="section2_content">

        <div className="desktop-only">
          <Card
            image={image3}
            video={video3}
            title="Artificial Intelligence"
            text="Leverage our cutting-edge AI to unlock actionable insights. Transform your real-time data into a strategic asset with Nom Nom AI, ensuring your company not only survives but thrives in the digital era."
          />
        </div>
        <div className="mobile-only">
          <Card
            image={image3mobile}
            title="Artificial Intelligence"
            text="Leverage our cutting-edge AI to unlock actionable insights. Transform your real-time data into a strategic asset with Nom Nom AI, ensuring your company not only survives but thrives in the digital era."
          />
        </div>

        <div className="desktop-only">
          <Card
            image={image1}
            video={video1}
            title="Managed Data"
            text="Optimize your company's data performance to automate, analyze, collect, model and store data effectively. Our solutions significantly improve OpEx/CapEx, offering a scalable team to mitigate employee turnover."
          />
        </div>
        <div className="mobile-only">
          <Card
            image={image1mobile}
            title="Managed Data"
            text="Optimize your company's data performance to automate, analyze, collect, model and store data effectively. Our solutions significantly improve OpEx/CapEx, offering a scalable team to mitigate employee turnover."
          />
        </div>

        <div className="desktop-only">
          <Card
            image={image2}
            video={video2}
            title="Data Security"
            text="Safeguard your data across every phase from transfer to archiving, with Nom Nom AI's Data Security solutions. Our approach ensures the safe handling and disposal of data, bolstered by advanced observability measures."
          />
        </div>
        <div className="mobile-only">
          <Card
            image={image2mobile}
            title="Data Security"
            text="Safeguard your data across every phase from transfer to archiving, with Nom Nom AI's Data Security solutions. Our approach ensures the safe handling and disposal of data, bolstered by advanced observability measures."
          />
        </div>
        
      </div>
      <div className="section2_content2">
        <Trusted />
        <Section3 />
        <Accredations />
      </div>
    </div>
  );
}

export default SectionTwo;
