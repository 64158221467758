import React, { useEffect, useRef, useState } from "react";
import "./Trusted.scss";
// import image from "../../Assets/Images/trusted.svg";
import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/all";
// import { useGSAP } from "@gsap/react";
import client1 from "../../Assets/Images/client1.png";
import client2 from "../../Assets/Images/client2.png";
import client3 from "../../Assets/Images/client3.png";
import client4 from "../../Assets/Images/client4.png";
import client5 from "../../Assets/Images/client5.png";
import client6 from "../../Assets/Images/client6.png";
import client7 from "../../Assets/Images/client7.png";
import client8 from "../../Assets/Images/client8.png";
import client9 from "../../Assets/Images/client9.png";
import client10 from "../../Assets/Images/client10.svg";
import client11 from "../../Assets/Images/client11.png";
import client12 from "../../Assets/Images/client12.png";
import client13 from "../../Assets/Images/client13.png";
import client14 from "../../Assets/Images/client14.png";
import client15 from "../../Assets/Images/client15.png";
import client16 from "../../Assets/Images/client16.png";
import client17 from "../../Assets/Images/client17.png";

function Trusted() {
  const containerRef = useRef(null);
  const card1 = useRef(null);
  const card2 = useRef(null);

  useEffect(() => {
    if (window.innerWidth > 768) {
      const container = containerRef.current;

      // Set up GSAP animation timeline
      const t1 = gsap.timeline({ paused: true, markers: true });
      const t2 = gsap.timeline({ paused: true, markers: true });
      const t3 = gsap.timeline({ paused: true, markers: true });

      t1.to(container, { scrollTop: 150, duration: 1 });
      t2.to(
        card1.current,
        {
          transform: "translateX(50%)",
          duration: 1,
        },
        0
      ).fromTo(
        card2.current,
        {
          transform: "translateX(30%)",
          duration: 1,
        },
        {
          transform: "translateX(0%)",
          duration: 1,
        },
        0
      );

      t3.to(container, { scrollTop: 0, duration: 1 });
      // Hover event handling
      container.addEventListener("mouseenter", () => {
        t1.restart().play(); // Restart and play t1
        t2.restart().play(); // Restart and play t2
      });

      container.addEventListener("mouseleave", () => {
        t3.restart().play(); // Restart and play t3
        t2.reverse();
      });
    }
  }, []);

  const clients = [
    {
      image: client11,
      title: "Clover - Fiserv",
      description:
        "",
      category: "FinTech",
    },
    {
      image: client17,
      title: "IGDA",
      description:
        "We have a cleaner, faster, more reliable data architecture thanks to Nom Nom Data. - Mark Tait, Director of Product at SciPlay",
      category: "Gaming",
    },
    {
      image: client3,
      title: "SciPlay",
      description:
        "We have a cleaner, faster, more reliable data architecture thanks to Nom Nom Data. - Mark Tait, Director of Product at SciPlay",
      category: "Gaming",
    },
    {
      image: client2,
      title: "Scientific Games - Brookfield Business Partners",
      description:
        "",
      category: "Gaming",
    },
    {
      image: client10,
      title: "Storable - EQT",
      description:
        "Nom Nom Data has had an enormous impact on our company and our data engineering team. They helped us improve and modernize our data platform and tool suite in a way that has enhanced reliability, supportability, and maintainability. - Jerry Gregoire, Director of Data Engineering at Storable",
      category: "SaaS/ Other",
    },
    {
      image: client1,
      title: "Tenderheart - Health Outcomes",
      description:
        "The stability and extensibility of the data automation Nom Nom Data built has been fantastic.  Their expertise and ability to create foundational structures that power our dashboards has allowed me to provide reports that were infuriating to work with before. - Zach Ferris, Senior Analyst at Tenderheart Health Outcomes",
      category: "Healthcare",
      // ref: card1,
    },
    {
      image: client13,
      title: "SafeHello",
      description:
        "These guys are rockstars and will definitely be our data engineering solution moving forward. - Jon Carr, CEO of SafeHello",
      category: "SaaS/ Other",
    },
    {
      image: client9,
      title: "Big Blue Swim - Level 5 Capital Partners",
      description:
        "",
      category: "Education",
    },
    {
      image: client8,
      title: "Kidstrong",
      description:
        "",
      category: "Education",
    },
    {
      image: client4,
      title: "Wimo Games",
      description:
        "",
      category: "Gaming",
    },
    {
      image: client7,
      title: "Lucky Day",
      description:
        "",
      category: "Gaming",
    },
    {
      image: client5,
      title: "Gandy Ink",
      description:
        "",
      category: "Gaming",
    },
    {
      image: client6,
      title: "Ink Games",
      description:
        "",
      category: "Gaming",
    },
    {
      image: client12,
      title: "Dance Fight - Virtual Arts, Inc",
      description:
        "",
      category: "SaaS/ Other",
    },
    {
      image: client14,
      title: "PXP Solutions",
      description:
        "",
      category: "SaaS/ Other",
      ref: card2,
    },
    {
      image: client15,
      title: "EdTech JPA",
      description:
        "",
      category: "Education",
      //ref: card2,
    },
    {
      image: client16,
      title: "Irvine School District",
      description:
        "",
      category: "Education",
      //ref: card2,
    },
  ];

  // Popup about us
  const [showPopup, setShowPopup] = useState(false);

  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  // Popup testimonials
  const [showPopup2, setShowPopup2] = useState(false);

  const handleOpenPopup2 = () => {
    setShowPopup2(true); 
  };

  const handleClosePopup2 = () => {
    setShowPopup2(false); 
  };

  return (
    <>
      <div className="trusted_container" id="about">
        <div className="trusted_card_cont" ref={containerRef}>
          {clients.map((client, index) => (
            <div className="trusted_card" ref={client?.ref}>
              <img
                src={client.image}
                className="trusted_card_img"
                alt={client.title}
              />
              <div className="trusted_card_text">{client.description}</div>
              <div className="trusted_card_catg">
                <div className="trusted_card_catg_text">Category</div>
                <div className="trusted_card_catg_name">{client.category}</div>
              </div>
              <div className="trusted_card_space"></div>
            </div>
          ))}
        </div>
        <div className="trusted_overlay"></div>
        <div className="trusted_footer">
          <div className="section2_title">
            <h2>
              Valued by our customers for our reliability and integrity.
            </h2>
            <button onClick={handleOpenPopup}>About Us</button>
            <button onClick={handleOpenPopup2}>Testimonials</button>
          </div>
          {/* <div className="trusted_footer_text">
            Valued by our customers for our reliability and integrity.
          </div> */}
        </div>
      </div>

      <div className={showPopup ? 'modal display-block' : 'modal display-none'}>
        <section className="modal-main">
          <button onClick={handleClosePopup}>×</button>
          <div className="modal-main-about">
            <h2>
              About Us
            </h2>
            <p>
              Nom Nom is a <strong>pioneer in data engineering & automation as a service</strong>. Our knowledge and expertise delivers the best outcomes with artificial intelligence.
              <br/><br/>
              Our solutions reduce OpEx/CapEx, streamline operations, and ensure a competitive edge.
              <br/><br/>
              In today's digital landscape, generating an unprecedented volume of data, businesses are in dire need of sophisticated, real-time analytics and adaptable, scalable solutions.
              <br/><br/>
              Nom Nom emerges as a leader in revolutionizing data management, thanks to our innovative cutting-edge approach.
              <br/><br/>
              We address the complex challenge of managing, integrating, and automating vast volumes of data for businesses, offering services designed to simplify complex data ecosystems.
              <br/><br/>
              This crucial shift and urgent need for advanced data management, makes Nom Nom a key partner in the data-driven world and artificial intelligence.
            </p>
          </div>
        </section>
      </div>

      <div className={showPopup2 ? 'modal display-block' : 'modal display-none'}>
        <section className="modal-main">
          <button onClick={handleClosePopup2}>×</button>
          <h2>
            Testimonials
          </h2>
          <div className="modal-card">
            <p>
              “We have a cleaner, faster, more reliable data architecture thanks to Nom Nom Data.” 
            </p>
            <h4>
              Mark Tait, Director of Product at SciPlay
            </h4>
          </div>
          <div className="modal-card">
            <p>
              “Nom Nom Data has had an enormous impact on our company and our data engineering team. They helped us improve and modernize our data platform and tool suite in a way that has enhanced reliability, supportability, and maintainability." 
            </p>
            <h4>
              Jerry Gregoire, Director of Data Engineering at Storable
            </h4>
          </div>
          <div className="modal-card">
            <p>
              "The stability and extensibility of the data automation Nom Nom Data built has been fantastic.  Their expertise and ability to create foundational structures that power our dashboards has allowed me to provide reports that were infuriating to work with before.” 
            </p>
            <h4>
              Zach Ferris, Senior Analyst at Tenderheart Health Outcomes
            </h4>
          </div>
          <div className="modal-card">
            <p>
              "These guys are rockstars and will definitely be our data engineering solution moving forward" 
            </p>
            <h4>
              Jon Carr, CEO of SafeHello
            </h4>
          </div>
        </section>
      </div>
    </>
  );
}

export default Trusted;