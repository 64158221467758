import React from "react";
import "./Section7.scss";
import Button from "../../Components/Button/Button";
import arrow from "../../Assets/Images/arrow.svg";
import logo from "../../Assets/Images/logo.svg";

function Section7() {
  return (
    <div className="section7_main" id="contact">
      {/* <div className="section7_title">Ready to Play with Nomi?</div> */}
      <div className="contactUs_box">
        <div className="contactUs_left">
          <div className="contactUs_left_title">Talk To Us!</div>
          <div className="contactUs_left_text">
            We know you have questions, and we're here to help. With years of experience, we have the answers you need.
          </div>
          <Button onClick={() => window.location = 'https://meetings.hubspot.com/nomnom/30-min'}
            variant2
            text={
              <div className="contactUs_left_button">
                <a href="https://meetings.hubspot.com/nomnom/30-min" className="contactUs_left_button_link">
                  <div className="contactUs_left_button_text">Contact Us</div>
                  <img
                    src={arrow}
                    alt="arrow"
                    className="contactUs_left_button_arrow"
                  />
                </a>
              </div>
            }
          />
        </div>
        {/* <div className="contactUs_divider"></div>
        <div className="contactUs_left">
          <div className="contactUs_left_title">Connect With Us!</div>
          <div className="contactUs_left_text">
            NomNom.ai is the chosen ally for a spectrum of industry leaders,
            reflecting our commitment to fostering successful
          </div>
          <Button
            variant
            text={
              <a href="https://www.linkedin.com/company/nomnomdata" target="_blank" rel="noopener noreferrer">
                Follow Us On LinkedIn
              </a>
            }
          />
        </div> */}
      </div>
    </div>
  );
}

export default Section7;
