import React, { useEffect } from 'react';
import "./Style.css";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { Link } from "react-router-dom";

import blogImage from "../../Assets/Images/blog-01.png";

function Blog() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  return (
    <div className="main_page_cont">
      <Header />

      <div
        style={{
          backgroundColor: "var(--ofWhite)",
          flexDirection: "column",

          alignItems: "center",
        }}
      >

        <div className="page-header">
          <h1>
            Blog
          </h1>
        </div>

        <div className="page-content">
          <div className="page-container">

            <div className='blog-post'>
              <div>
                <img src={blogImage} alt="Why Your Database Fails as Your Mobile App Scales Up: Unraveling the Challenges" className='blog-post-img'/>
              </div>
              <div>
                <div className='content'>
                  <h2>
                    Why Your Database Fails as Your Mobile App Scales Up: Unraveling the Challenges
                  </h2>
                  <p>
                  In the fast-paced digital world we inhabit, mobile applications have become an integral part of our daily lives. From social networking to e-commerce and everything in between, mobile apps have revolutionized the way we interact with technology. However...
                  </p>

                  <Link to="/blog/why-your-database-fails-as-your-mobile-app-scales-up" className='btn-link'>Read more</Link>
                  
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#1E1E1E",
          display: "flex",
          justifyContent: "center",
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
        }}
      >
        <Footer />
      </div>
    </div>
  );
}

export default Blog;
