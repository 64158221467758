import React from "react";
import { Routes, Route } from "react-router-dom";
import MainPage from "./Pages/MainPage/MainPage";
import PrivacyPolicy from "./Pages/Legal/PrivacyPolicy";
import TermsAndConditions from "./Pages/Legal/TermsAndConditions";
import GDPR from "./Pages/Legal/GDPR";

import Blog from "./Pages/Blog/Blog";
import Post1 from "./Pages/Blog/Posts/Post1";

function AllRoutes() {
  return (
    <Routes>
      <Route index element={<MainPage />} />
      <Route path="/home" element={<MainPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/gdpr" element={<GDPR />} />
      <Route path="*" element={<MainPage />} />

      <Route path="/blog" element={<Blog />} />
      <Route path="/blog/why-your-database-fails-as-your-mobile-app-scales-up" element={<Post1 />} />
    </Routes>
  );
}

export default AllRoutes;
