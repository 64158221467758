import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import logo from "../../Assets/Images/logoWhite.svg";

function Footer() {
  const [modalOpen, setModalOpen] = useState(false);

  // Function to close the modal
  const closeModal = () => {
    setModalOpen(false);
    localStorage.setItem('gdprModalClosed', 'true');
  };

  useEffect(() => {
    // Check if the modal has been closed previously
    const modalClosed = localStorage.getItem('gdprModalClosed');
    if (!modalClosed) {
      setModalOpen(true);
    }
  }, []);

  // Function to prevent closing the modal when clicking outside of it
  const handleModalClick = (e) => {
    e.stopPropagation(); // Stop the event from bubbling up
  };

  const [showPopup, setShowPopup] = useState(false);
  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };


  return (
    <>

      <div className={showPopup ? 'modal display-block' : 'modal display-none'}>
        <section className="modal-main">
          <button onClick={handleClosePopup}>×</button>
          <div className="modal-main-about">
            <h2>
              About Us
            </h2>
            <p>
              Nom Nom is a <strong>pioneer in data engineering & automation as a service</strong>. Our knowledge and expertise delivers the best outcomes with artificial intelligence.
              <br/><br/>
              Our solutions reduce OpEx/CapEx, streamline operations, and ensure a competitive edge.
              <br/><br/>
              In today's digital landscape, generating an unprecedented volume of data, businesses are in dire need of sophisticated, real-time analytics and adaptable, scalable solutions.
              <br/><br/>
              Nom Nom emerges as a leader in revolutionizing data management, thanks to our innovative cutting-edge approach.
              <br/><br/>
              We address the complex challenge of managing, integrating, and automating vast volumes of data for businesses, offering services designed to simplify complex data ecosystems.
              <br/><br/>
              This crucial shift and urgent need for advanced data management, makes Nom Nom a key partner in the data-driven world and artificial intelligence.
            </p>
          </div>
        </section>
      </div>
    
    {modalOpen && (
      <div id="modal" className="modal modal-legal" onClick={handleModalClick}>
        <div className="modal-content">
          <h3>
            Welcome to our website! We care about your privacy.
          </h3>
          <p>
            This website uses cookies and data collection to enhance your experience for analytic and contact purposes. 
            By continuing to use our site, you consent to the use of cookies in accordance with our privacy policy, our terms and conditions, and GDPR laws. 
            We value your privacy and are committed to protecting your personal data. To learn more about how we handle your information, please review the links below:
            <br /><br />
            <a href="/privacy-policy" target="_blank">Privacy Policy</a> 
            <a href="/terms-and-conditions" target="_blank">Terms & Conditions</a> 
            <a href="/gdpr" target="_blank">GDPR</a>
          </p>
          <button className="close button_grad_main" onClick={closeModal}>Got it!</button>
        </div>
      </div>
    )}

    <div className="footer_main_div">
      <div className="footer_upper">
        <div className="footer_upper_left">
          <img src={logo} alt="logo" className="footer_logo" />
          <p className="footer-sub-logo-text">
            Managed Data, Observability, Insight & Data Security Solutions
          </p>
          <div className="footer_socials">
            {/* Social media links */}
          </div>
        </div>
        <div className="footer_upper_right">
          <div className="footer_upper_right_title">
            We make AI simple and your Data scalable
            <div className="footer-nav">
              <a href="/">Home</a>
              <a onClick={handleOpenPopup}>About</a>
              <a href="https://meetings.hubspot.com/nomnom/30-min">Contact Us</a>
              <Link to="/blog">Blog</Link>
              <Link to="/privacy-policy">Privacy Policy</Link>
              <Link to="/terms-and-conditions">Terms & Conditions</Link>
              <Link to="/gdpr">GDPR</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_lower">
        <div className="footer_lower_left">
          <div className="footer_lower_left_title footer-m-sm-text">
            2024 ©️ Nom Nom Data Inc.
          </div>
          <div className="footer_lower_left_title text-center">
            A wholly owned subsidiary of Nom Nom AI Inc.
          </div>
        </div>
        <a className="footer_lower_right_title" href="mailto:hello@nomnom.ai">
          hello@nomnom.ai
        </a>
      </div>
    </div>
    </>
  );
}

export default Footer;