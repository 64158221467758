import React from "react";
import "./SectionOne.scss";
import Button from "../../Components/Button/Button";
import arrow from "../../Assets/Images/arrow.svg";
// Header logos
import snowflake from "../../Assets/Images/logo-snowflake.png";
import aws from "../../Assets/Images/logo-aws.png";
import google from "../../Assets/Images/logo-google.png";
import vanta from "../../Assets/Images/logo-vanta.png";
import azure from "../../Assets/Images/logo-azure.png";

function SectionOne() {

  const handleScroll = (className) => {
    const element = document.querySelector(`.${className}`);

    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  };

  const handleScrollToContact = () => {
    handleScroll("section7_main");
  };
  
  return (
    <div className="main_sec1">
      <div className="main_sec2">
        <div className="intro">
          <span className="line-001">
            As a data labeling and AI <br className="mobile-only"/> preprocessing company, 
            <br/>
            <strong>Nom Nom is the trusted <br className="mobile-only"/> data foundry for AI.</strong>
          </span>
          <span className="line-002">
            Our data engine, generates all the necessary data to fuel all the leading LLMs.
          </span>
          <span className="line-002">
            Click <a href='https://drive.google.com/file/d/125odoI2f1H1xTEU1sFmjNKMSRkfC6-fk/view'>here</a> to see how it works!
          </span>
          
        </div>
        <div className="main_sec1_info">
          <div className="main_sec1_new">
            <span>NEWS:</span>
            Avoid data breaches & compromises with our data disposition solutions!
          </div>
          <h1 className="main_sec1_title">
            <span className="blue-text">Patent Pending</span> - A suite of Managed Data Services to optimize Business Outcomes and Artificial Intelligence
          </h1>
          <div className="main_sec1_subTit">
            Transform your data scalability today with our revolutionary AI ecosystem.
          </div>
          <div className="main_sec1_cat">
            <Button
              onClick={() => window.location = 'https://meetings.hubspot.com/nomnom/30-min'}
              text={
                <div className="contact_sec1">
                  Contact Us
                  <img src={arrow} alt="Arrow icon" />
                </div>
              }
            />
          </div>
          <div className="sec1_cert">
            Certifications
            <div className="sec1_cert_sub">
              <img src={snowflake} alt="snowflake" />
              <img src={aws} alt="aws" /> 
              <img src={google} alt="google" />
              <img src={vanta} alt="vanta" />
              <img src={azure} alt="azure" />
            </div>
            <div className="cert-text">
              <p>and more…</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionOne;
