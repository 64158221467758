import React, { useState } from "react";
import "./Section3.scss";
import int1 from "../../Assets/Images/snow.png";
import google from "../../Assets/Images/google.png";
import Button from "../../Components/Button/Button";
import arrow from "../../Assets/Images/arrow.svg";
import gsap from "gsap";
import { MotionPathPlugin } from "gsap/all";
import bigquery from "../../Assets/Images/bigquery.png";
import redshift from "../../Assets/Images/redshift.png";
import hootsuite from "../../Assets/Images/hootsuite.png";
import hubspot from "../../Assets/Images/hubspot.png";
import shopify from "../../Assets/Images/shopify.png";
import appStore from "../../Assets/Images/App-Store-Logo.png";
import googlePlay from "../../Assets/Images/googlePlay.png";
import appsFlyer from "../../Assets/Images/appsFlyer.png";
import brightree from "../../Assets/Images/Brightree.png";
import facebook from "../../Assets/Images/facebook.png";
import helpshift from "../../Assets/Images/helpshift.png";
import zendesk from "../../Assets/Images/zendesk.png";
import meta from "../../Assets/Images/Facebook Meta.png";
import slack from "../../Assets/Images/slack.png";
import sendgrid from "../../Assets/Images/sendgrid.png";
import shipstation from "../../Assets/Images/shipstation.png";
import mysql from "../../Assets/Images/mysql.png";
import filemaker from "../../Assets/Images/fileMaker.png";
import s3 from "../../Assets/Images/s3.png";
import blobStorage from "../../Assets/Images/blobstorage.png";
import ip2Location from "../../Assets/Images/ip2locations.png";
import manageOrders from "../../Assets/Images/manageOrders.png";
import barcode from "../../Assets/Images/barcode.png";
import googleAds from "../../Assets/Images/googleAds.png";
import steam from "../../Assets/Images/steam.png";
import ftp from "../../Assets/Images/ftp.png";
import sqlserver from "../../Assets/Images/sqlserver.png";
import googleStorage from "../../Assets/Images/googlestorage.png";
import adp from "../../Assets/Images/adp.png";
import onedrive from "../../Assets/Images/onedrive.png";

function Section3() {

  const handleScroll = (className) => {
    const element = document.querySelector(`.${className}`);
  
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  };

  const handleScrollToContact = () => {
    handleScroll("section7_main");
  };

  gsap.registerPlugin(MotionPathPlugin);
  const [clicked, setClicked] = useState(false);
  const t1 = gsap.timeline({ paused: true });
  const t2 = gsap.timeline({ paused: true });

  t1.to(".integration_globe", {
    display: "block",
    transform: "translateY(0%)",
    duration: 1,
  }).to(".integration_globe", {
    display: "block",
    transform: "translateY(2%)",
    duration: 0.5,
  });
  t2.to(".integration_globe", {
    display: "block",
    transform: "translateY(100%)",
    duration: 1,
  });
  // useEffect(() => {
  //   if (clicked) {
  //     t1.play();
  //   } else {
  //     t2.play();
  //   }
  // }, [clicked]);
  const integrations = [
    { image: int1, title: "Snowflake" },
    {
      image: bigquery,
      title: "BigQuery",
    },
    {
      image: redshift,
      title: "Redshift",
    },
    {
      image: google,
      title: "Google Analytics",
    },
    {
      image: hootsuite,
      title: "Hootsuite",
    },
    {
      image: hubspot,
      title: "Hubspot",
    },
    {
      image: shopify,
      title: "Shopify",
    },
    {
      image: appStore,
      title: "Apple App Store",
    },
    {
      image: googlePlay,
      title: "Google Play Store",
    },
    {
      image: appsFlyer,
      title: "AppsFlyer",
    },
    {
      image: brightree,
      title: "Brightree",
    },
    {
      image: facebook,
      title: "Facebook Ads",
    },
    {
      image: helpshift,
      title: "Helpshift",
    },
    {
      image: zendesk,
      title: "Zendesk",
    },
    {
      image: meta,
      title: "MetaQuest",
    },
    {
      image: slack,
      title: "Slack",
    },
    {
      image: sendgrid,
      title: "SendGrid",
    },
    {
      image: shipstation,
      title: "ShipStation",
    },
    {
      image: mysql,
      title: "MySQL",
    },
    {
      image: filemaker,
      title: "FileMaker",
    },
    {
      image: s3,
      title: "S3 File Compressor",
    },
    {
      image: blobStorage,
      title: "Blob Storage",
    },
    {
      image: ip2Location,
      title: "IP2Location",
    },
    {
      image: manageOrders,
      title: "Manage Orders",
    },
    {
      image: barcode,
      title: "Barcode PDF Creator",
    },
    {
      image: googleAds,
      title: "Google Ads",
    },
    {
      image: steam,
      title: "Steam",
    },
    {
      image: ftp,
      title: "FTP",
    },
    {
      image: sqlserver,
      title: "SQL Server",
    },
    {
      image: googleStorage,
      title: "Google Storage",
    },
    {
      image: adp,
      title: "ADP",
    },
    {
      image: onedrive,
      title: "OneDrive",
    },
  ];
  return (
    <div className="section3_main">
      <div className="integration_cont2">
        <div className="integrtion_title m-top">Our Solutions </div>
        <div className="integration_text">
          <h2>
            Artificial Intelligence & Machine Learning
          </h2>
          <ul>
            <li>
              <span>AI Development:</span>  <br/>
              AI systems to automate decision-making processes and enhance operational efficiency.
            </li>
            <li>
              <span>Model Training:</span>  <br/>
              Refine machine learning models to uncover new insights and improve predictive accuracy.
            </li>
            <li>
              <span>LLM Optimization:</span>  <br/>
              Enhance large language models to boost performance and increase computational efficiency.
            </li>
            <li>
              <span>AI Prompt Engineering:</span>  <br/>
              Improve response accuracy, and tailored user experiences through AI interactions.
            </li>
            <li>
              <span>Predictive Analysis:</span>  <br/>
              Use historical data to predict future trends, behaviors, and outcomes.
            </li>
            <li>
              <span>Sentiment Analysis:</span>  <br/>
              Analyze text data to determine the sentiment behind words, enhancing the understanding of data.
            </li>
            <li>
              <span>DocChat:</span>  <br/>
              The ability to chat, summarize, and ask questions with documents.
            </li>
            <li>
              <span>On-Prem AI Chat:</span>  <br/>
              Converting unstructured data into structured insights with automated LLM training.
            </li>
            <li>
              <span>Document Taxonomy:</span>  <br/>
              Organize and classify documents based on predefined categories for easier retrieval and analysis.
            </li>
            <li>
              <span>Data Labeling:</span>  <br/>
              Categorize raw data with relevant tags and/or labels to make it usable for machine learning.
            </li>
          </ul>
        </div>
        <div className="integration_text">
          <h2>
            Managed Data Services
          </h2>
          <ul>
            <li>
              <span>Data Integration:</span>  <br/>
              Seamlessly connect all your data sources and receive clean, reliable data.
            </li>
            <li>
              <span>Data Automation:</span>  <br/>
              Automate repetitive data tasks to increase efficiency and reduce human error.
            </li>
            <li>
              <span>Data Engineering:</span>  <br/>
              Design, build, and manage scalable data pipelines to support analytical and operational workloads.
            </li>
            <li>
              <span>Data Warehousing:</span>  <br/>
              Centralize data storage to enable complex queries and analysis without impacting operational systems.
            </li>
            <li>
              <span>Data Architecture:</span>  <br/>
              Develop a strategic framework for managing data across the organization, ensuring data quality and accessibility.
            </li>
            <li>
              <span>Data Science:</span>  <br/>
              Apply statistical analysis, machine learning, and predictive modeling to extract insights and drive business outcomes.
            </li>
            <li>
              <span>Data Governance:</span>  <br/>
              Ensure proper management, privacy, and compliance of data across the organization.
            </li>
            <li>
              <span>Data Cleansing:</span>  <br/>
              Correcting or removing corrupt or inaccurate data to improve data quality and reliability.
            </li>
          </ul>
        </div>
        <div className="integration_text">
          <h2>
            Data Security
          </h2>
          <ul>
            <li>
              <span>Data Observability:</span>  <br/>
              Gain real-time visibility into system health and data flows to proactively address issues.
            </li>
            <li>
              <span>Insight Technology:</span>  <br/>
              Utilize advanced analytics for deeper insights into data security and performance trends.
            </li>
            <li>
              <span>Data Disposition:</span> <br/>
              Safely dispose of obsolete data to comply with privacy regulations and reduce storage costs.
            </li>
          </ul>
        </div>
        <div className="integration_text">
          <h2>
            Support
          </h2>
          <ul>
            <li>
              <span>Enterprise-Level Support & 24/7 Hotline Concierge:</span>  <br/>
              For immediate service and to maintain maximum uptime.
            </li>
          </ul>
        </div>
      </div>
      <div className="integrtion_cont">
        <div className="integrtion_title">We offer a range of integrations </div>
        {/* <div className="integrtion_sub">We offer a range of integrations</div> */}
        <div className="integration_body">
          <div className="integration_items">
            {integrations.map((item, index) => (
              <div className="integration_item" key={index}>
                <img
                  src={item.image}
                  className="integration_item_img"
                  alt={item.title}
                />
                <div className="integration_item_title">{item.title}</div>
              </div>
            ))}
          </div>
          <Button
            onClick={() => window.location = 'https://meetings.hubspot.com/nomnom/30-min'}
            text={
              <div className="contact_sec1">
                Deploy
                <img src={arrow} alt="Arrow icon" />
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
}

export default Section3;
