import React from "react";
import "./Button.scss";
function Button({ text, onClick, variant, variant2 }) {
  return (
    <button
      className={
        variant
          ? "button_var_main"
          : variant2
          ? "button_black_main"
          : "button_grad_main"
      }
      onClick={onClick}
    >
      {text}
    </button>
  );
}

export default Button;
